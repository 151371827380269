.wallet{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wallet-item{
    display: flex;
padding: 20px;
box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.24);
width: 40%;
justify-content: center;
align-items: center;

margin: 1%;
}
.wallet-value{
    line-height: 1;
    margin: 0%;
    padding: 0%;
}
.wallet-label{
    line-height: 1;
    margin: 0%;
    padding: 0%;
    font-weight: 700;
    margin-right: 20px;
}
.wallet-button{
    display: flex;
    width: 43%;
  
    gap: 1%;
}
.wallet-buttons{
    background-color: rgb(222, 31, 31);
    color: white;
    border-style: none;
    padding: 10px;
    width: 96%;
    border-radius: 4px;
    
}
.history-heading{
    color: black;
}
.table-row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgb(0, 191, 255);
   
}
.th{
    line-height: 1;
    padding: 10px;
    margin: 0%;
}
.td{
    line-height: 1;
    padding: 10px;
    margin: 0%;
}
@media (max-width: 1080px) {
    .wallet{
        width: 98%;
       padding-top: 50px;
    }
    .wallet-item{
      
    width: 80%;
 
  
    }
    .table{
        width: 83%;
       
       
    }
    .wallet-button{
        display: flex;
        width: 83%;
      
        gap: 1%;
    }

}