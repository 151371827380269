.user-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.register-user-item{
    width: 90%;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.24);
    margin: 10px;
    display: flex;
}
.register-user-item p{
    width: 15%;
    overflow: hidden;
    border-right: 1px solid black;
padding-left: 1%;
padding-right: 1%;
font-size: 12px;
}
.register-user-item p:last-child{
   width: 10%;
    border-right: 0px solid black;

}
.register-user-item p:first-child{
    width: 5%;
    
   
 
 }
 .heading{
    color: black;
    margin-top: 10px;
 }
.referal-item{
    width: 90%;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.24);
    margin: 10px;
    display: flex;
}
.referal-item p{
    width: 33%;
    overflow: hidden;
    border-left: 1px solid black;
padding-left: 1%;
padding-right: 1%;
font-size: 12px;
}
.referal-item p:first-child{
    width: 10%;
    border-left: 0px solid black;
 
 }
 @media (max-width: 1080px) {
  
    .referal-item p{
        width: 25%;
       
    }
 
    .register-user-item p{
        width: 18%;
        overflow: hidden;
    font-size: 11px;
    }
    .register-user-item p:last-child{
  
    
    }
    .register-user-item p:first-child{
        width: 2%;
        
       
     
     }

 }