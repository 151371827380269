.star-icon {
    color: rgb(194, 194, 194);
    margin-bottom: 10px;
  }
  .Heading{
    font-size: 20px;
padding-top: 50px;
  }
  .submit-btn {
    padding: 10px;
    width: 100%;
    background-color: #f53224;
    color: white;
    border-style: none;
  }
  
  .todaywork-item {
    width: 95%; 
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.24);
    padding: 10px;
    margin: 30px;
    text-align: left;
    background-color: white;
    border-radius: 5px;
   
  }
  
  .product-img {
    width: 100%;
    height: 200px;
    border-bottom: 1px solid rgb(216, 216, 216);
  }
  
  .todaywork-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr)); 
    gap: 20px;
    padding: 20px;
    width: 80%;
  }
  .submit-btn:disabled {
    background-color: lightgray; /* Light gray background when disabled */
    color: rgb(0, 0, 0); /* Dark gray text when disabled */
    cursor: not-allowed; /* Not-allowed cursor */
  }
  @media (max-width: 1080px) {

    .todaywork-grid {
      grid-template-columns: repeat(auto-fill, minmax(130px, 2fr)); 
      padding: 0px;
      width: 90%;
    }
    .product-img {
      height: 130px;
    }
    .todaywork-item {
      width: 95%; 
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.24);
      padding: 10px;
      margin: 10px;
      text-align: left;
      background-color: white;
      border-radius: 5px;
     
    }
  }